import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import noticePreviewData from '../../../../data/previews/notice.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Notice = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Notice;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noticeType: false,
    };
  }

  handleTypeChange = type => {
    this.setState({
      noticeType: type,
    });
  };

  render() {
    let actions = [];

    if (this.state.noticeType === 'warning') {
      actions = [{ text: 'Upgrade Account' }];
    } else if (this.state.noticeType === 'critical') {
      actions = [{ text: 'Upload Video' }];
    } else if (this.state.noticeType === 'confirmation') {
      actions = [{ text: 'Upload Video' }];
    }

    return (
      <PageWithSubNav
        pageType="design"
        title="Notice"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Notifications"
          tierThree="Notice"
        />
        <V5Notice
          componentName="Notice"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/notifications-notice--docs"
        />
        <Paragraph>
          If the information does pertain to just one section, use a{' '}
          <Link href="/components/notifications/note" isDesignCodeLink>
            note
          </Link>
          . (And for immediate feedback as the result of a workflow, go with a{' '}
          <Link href="/components/notifications/toast" isDesignCodeLink>
            toast
          </Link>
          .)
        </Paragraph>
        <PageNavigation
          links={[
            'Type',
            'State',
            'Mobile',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Platform',
          ]}
        />

        <Section title="Type">
          <Paragraph>
            All page notices are displayed in line, fixed to the top of the
            primary content area of the given page.
          </Paragraph>
          <ComponentPreview
            name="NoticeType"
            layout="fullWidth"
            previewData={noticePreviewData.types}
            onChange={this.handleTypeChange}>
            <Notice actions={actions} />
          </ComponentPreview>
        </Section>

        <Section title="State">
          <Paragraph>
            All notices appear as soon as the page loads. They should remain
            dismissable either with an actual dismiss or by completing the
            action listed. All notices (except for critical) should{' '}
            <em>permanently</em> disappear once they’ve been dismissed.
          </Paragraph>

          <Paragraph>
            Critical notices will disappear when dismissed, but only for that
            session. If the action has not been completed, the notice will
            reappear on the next page load.
          </Paragraph>
          <DontDo
            dontText="force notices to persist without the ability to dismiss."
            doText="include a way for users to remove the notice."
            imgFilename="notice-dismiss"
          />
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Space is at a premium when designing notices for mobile devices so
            be considerate of the message length within a notice.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Feedback</SectionSubhead>
          <Paragraph>
            Every notice provides a certain type of feedback, emphasized by a{' '}
            <Link
              href="/guidelines/colors/utility/design#Options"
              isDesignCodeLink>
              color
            </Link>{' '}
            and{' '}
            <Link href="/components/icons/feedback/design">feedback icon</Link>.
          </Paragraph>
          <DontDo
            dontText="interchange feedback colors and icons."
            doText="match feedback to the message type."
            imgFilename="notice-feedback"
          />

          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            Every critical notice should include an action to help the user
            solve their issue. The action itself is optional on all other
            notices. Just be sure to weigh the value it may (or may not) add.
          </Paragraph>

          <SectionSubhead>Position</SectionSubhead>
          <Paragraph>
            A notice is always positioned beneath the navbar within the primary
            content area of the given page. It should span the full width of the
            page until the natural max-width is achieved.
          </Paragraph>
          <DontDo
            dontText="position a page notice above a sidebar or side panel."
            doText="keep a notice in line with the primary content area."
            imgFilename="notice-position"
          />
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Case & Punctuation</SectionSubhead>
          <Paragraph>
            Notices follow our{' '}
            <Link href="http://hudl.com/design/writing/writing-for/teams/product">
              non-label text
            </Link>{' '}
            guidelines—use sentence case with appropriate punctuation.
          </Paragraph>
          <DontDo
            dontText="use crazy punctuation to level up the message."
            doText="use complete sentences followed by periods."
            imgFilename="notice-punctuation"
          />

          <SectionSubhead>Content Length</SectionSubhead>
          <Paragraph>
            Make the content readable and don’t go overboard.{' '}
            <strong>Keep it brief</strong> with the most important info at the
            front, ideally less than 200 characters.
          </Paragraph>
          <DontDo
            dontText="overwhelm the user with unnecessary information."
            doText="keep the message short and to the point."
            imgFilename="notice-contentlength"
          />

          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            Titles should be concise with keywords at the front. The content
            should relate directly to the action they’d need to take.
          </Paragraph>
          <DontDo
            dontText="explain the entire message in the title."
            doText="focus on the most important info."
            imgFilename="notice-title"
          />

          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            Try limiting actions to a single verb. If you can’t, stick to a
            three-word phrase.
          </Paragraph>
          <DontDo
            dontText="make button labels complete sentences."
            doText="keep things simple with a single verb."
            imgFilename="notice-actions"
          />
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Notifications / Notice" />

        <Section title="Platform">
          <PlatformTable />
        </Section>
      </PageWithSubNav>
    );
  }
}
